<template>
  <div class="vld-parent">
    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      loader="dots"
      :color="color"
    ></loading>
    <!--begin::Card-->
    <div class="card">
      <!--begin::Card body-->
      <div class="card-body pt-0">
        <div class="card mt-5 mt-xxl-8">

          <!--begin::Card body-->
          <div class="card-body">
            <!--begin::Tab Content-->
            <div class="tab-content">
              <!--begin::Tab panel-->
              <div
                class="card-body p-0 tab-pane fade show active table-responsive"
              >
                <!--begin::Table-->
                <table
                  class="table align-middle table-row-dashed fs-6 gy-5"
                  id="kt_table_users"
                  style="border-collapse: unset"
                >
                  <!--begin::Table head-->
                  <thead>
                    <!--begin::Table row-->

                    <tr
                      class="
                        text-start text-gray-400
                        fw-bolder
                        fs-7
                        text-uppercase
                        gs-0
                      "
                    >
                      <th class="min-w-125px">Notify Token</th>
                      <th class="text-end min-w-100px">จัดการ</th>
                    </tr>
                    <!--end::Table row-->
                  </thead>
                  <!--end::Table head-->
                  <!--begin::Table body-->
                  <tbody  v-if="NotifyToken.id"
                    class="text-gray-600 fw-bold"
                  >
                    <!--begin::Table row-->
                    
                      <tr>
                        <td>{{ NotifyToken.token }}</td>                    
                        <!--begin::Action=-->
                        <td class="text-end">
                          <router-link
                            :to="{
                              name: 'apps.taxs.notify.edit',
                              params: { id: NotifyToken.id },
                            }"
                          >
                            <a
                              class="
                                btn btn-light btn-active-light-primary btn-sm
                              "
                              >แก้ไข
                            </a>
                          </router-link>
                        </td>
                        <!--end::Action=-->
                      </tr>
                      <!--end::Table row-->
                 
                  </tbody>
                  
                </table>
              </div>
            
              <!--end::Table-->
            </div>
          </div>
        </div>
      </div>
      <!--end::Card body-->
    </div>
    <!--end::Card-->
  </div>
</template>

<script>
import { defineComponent, onMounted, onUpdated, ref } from "vue";
import {
  MenuComponent,
  ToggleComponent,
  DrawerComponent,
} from "@/assets/js/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { checkPage } from "@/core/helpers/checkpage";
import useTaxs from "@/core/services/api/tax/taxs";
import { useAbility } from "@casl/vue";
import useSweetalert from "@/core/helpers/sweetalert2";
export default defineComponent({
  name: "tax-notify-list",
  setup() {
    const NotifyToken = ref([]);
    const { getNotifyToken } = useTaxs();
    const { SconfirmNc } = useSweetalert();

    let isLoading = ref(true);
    let color = ref(process.env.VUE_APP_COLOR);

    const { can } = useAbility();
    onMounted(() => {
      getNotifyToken()
        .then((response) => {
          NotifyToken.value = response.data.data;
          isLoading.value = false;
        })
        .catch(() => {
          SconfirmNc(
            "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง",
            "error",
            "ลองอีกครั้ง !"
          );
          isLoading.value = false;
        });
      checkPage("tax-notify-list");
       setCurrentPageBreadcrumbs("จัดการ Notify", [
        "สอบถามภาษี",
      ]);
      DrawerComponent.reinitialization();
    });

    onUpdated(() => {
      ToggleComponent.bootstrap();
      MenuComponent.reinitialization();
    });



    return {
      can,
      NotifyToken,
      isLoading,
      color,
    };
  },
});
</script>

<style scoped></style>
