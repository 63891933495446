import { useRouter } from "vue-router";
import { useAbility } from "@casl/vue";

export const checkPage = (page) => {
  const router = useRouter();
  const { can } = useAbility();
  if (!can(page, "all")) {
    router.push({ name: "dashboard" });
  }
};
